'use strict';

var onSubmitFunc1 = function onSubmitFunc1(e) {
  e.preventDefault();

  if ($(this)[0].checkValidity() === true) {
    $('#step1form').off('submit', onSubmitFunc1);
    $('#step1form').submit();
  } else {
    e.preventDefault();
    $(this).addClass('was-validated');
    return false;
  }
};

$('#step1form').on('submit', onSubmitFunc1);

var onSubmitFunc2 = function onSubmitFunc2(e) {
  e.preventDefault();

  if ($(this)[0].checkValidity() === true && document.getElementById("checkbox1").checked && document.getElementById("checkbox2").checked) {
    $('#step2form').off('submit', onSubmitFunc2);
    $('#step2form').submit();
  } else {
    e.preventDefault();
    $(this).addClass('was-validated');
    return false;
  }
};

$('#step2form').on('submit', onSubmitFunc2);

var onSubmitLogin = function onSubmitLogin(e) {
  e.preventDefault();

  if ($(this)[0].checkValidity() === true) {
    $('#loginform').off('submit', onSubmitLogin);
    $('#loginform').submit();
  } else {
    e.preventDefault();
    $(this).addClass('was-validated');
    return false;
  }
};

$('#loginform').on('submit', onSubmitLogin);